<template>
    <div class="full-screen-loader">
        <UtilsLoader class="full-screen-loader__icon" />
    </div>
</template>

<script setup lang="ts"></script>

<style lang="postcss" scoped>
.full-screen-loader {
    @apply z-[9999] fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50;
    &__icon {
        @apply text-white w-28 h-28 block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
}
</style>
